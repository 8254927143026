#userProfileContainer{
    padding : 50px;
    width : 90%;
    background-color: white;
    margin : auto;
    margin-top : 80px;
    border : 1px solid gray;
}

#userProfileContainer h1{
    font-family: 'Sacramento', Tahoma, Geneva, Verdana, sans-serif;
    margin-bottom : 20px;
}



.userProfileInfo{

    display: flex;
    justify-content: space-around;
}

.userProfileInfo div .form-label{
    font-size : 12px;
}

.userProfileInfo div input{
    max-width : 120px;
}

.userProfileImgOptions{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.userProfileImgOptions div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 280px;
    flex: 1;
    /* This will allow each div to grow and shrink as needed */
    min-width: 120px;
    /* This will ensure that each div has a minimum width */
    margin-bottom: 20px;
}

.userProfileImg{
    margin : auto;
   
    height: 180px;
    max-height: 180px;
    width : 100px;
    background-size: contain;
    background-position: center;
        /* Cette propriété centre l'image dans la div */
        background-repeat: no-repeat;
        /* Cette propriété évite que l'image ne se répète */
        border : 1px solid black;
}
.userProfileImgOptions div label{
    font-size : 14px;
    font-family: 'Roboto';
    font-weight: bold;
    margin : auto;
    width : auto;
}

.userProfileImgOptions div input{
    width : 40px;
    margin : auto;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
}

.userProfileButton{
    margin-top: 50px;
}

.error-message {
    color: white;
    background-color: red;
    text-align: center;
    padding : 15px;
}

.success-message {
    color: white;
    background-color: green;
    text-align: center;
    padding : 15px;
}